import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ContentContainer from '../components/common/ContentContainer';
import Layout from '../components/Layout';
import LinkCard from './../components/common/LinkCard';
import Selection from './../components/common/Selection';
import { ResourceCardTypePill } from './../components/ResourceCard';
import SectionHeader from './../components/SectionHeader';
import SEO from '../components/Seo';
import ContentfulContent from '../components/common/ContentfulContent';

type featuredResource = {
  title: string;
};

type Resource = {
  id: string;
  title: string;
  body: {
    body: string;
  };
  featuredResources: featuredResource[];
  type: string;
  image: {
    fluid: {
      src: string;
    };
  };
  slug: string;
  shortDescription: any;
};

type Category = {
  value: string;
  label: string;
  selected: boolean;
};

// const defaultCategories: Category[] = [
//   {
//     value: 'videos',
//     label: 'Videos',
//     selected: true
//   },
//   {
//     value: 'assessments',
//     label: 'Assessments',
//     selected: true
//   },
//   {
//     value: 'curricular-materials',
//     label: 'Curricular Materials',
//     selected: true
//   },
//   {
//     value: 'reports',
//     label: 'Reports',
//     selected: true
//   }
// ];
const Resources: React.FC = () => {
  // const [categorySelections, setCategorySelections] = useState<Category[]>(
  //   defaultCategories
  // );
  const data = useStaticQuery(graphql`
    {
      allContentfulResource(
        filter: { node_locale: { eq: "en-US" } }
        sort: { fields: title, order: ASC }
      ) {
        edges {
          node {
            title
            body {
              body
            }
            image {
              fluid {
                src
              }
            }
            type
            slug
            featuredResources {
              title
            }
            shortDescription {
              shortDescription
            }
          }
        }
      }
    }
  `);

  const resources: Resource[] = data.allContentfulResource.edges
    .map((e: any) => e.node)
    .filter((r: any) => r.title !== 'test');

  // const applyContentFilters = (resource: Resource) => {
  //   const selectedCategories = categorySelections
  //     .filter((c: Category) => c.selected)
  //     .map((c: Category) => c.label);
  //   return selectedCategories.some((c: string) => c === resource.type);
  // };

  return (
    <Layout>
      <SEO title="Resources" />
      <ContentContainer>
        <div className="mt-6"></div>
        <SectionHeader
          superheader=""
          header="Resources for Teaching and Learning"
        />
        <div className="flex flex-col mt-8 sm:flex-row">
          {/* <div className="w-full pr-10 mb-4 mr-6 sm:w-1/4 sm:mb-0">
            <p className="mb-2 text-lg font-bold text-gray-500">
              Filter Categories
            </p>
            <div>
              {categorySelections.map((c: Category, i: number) => (
                <Selection
                  key={i}
                  item={c}
                  setSelected={() =>
                    setCategorySelections(
                      categorySelections.map((category: Category) => {
                        if (c.value === category.value) {
                          return { ...category, selected: !category.selected };
                        }
                        return category;
                      })
                    )
                  }
                />
              ))}
            </div>
          </div> */}
          <div className="w-full sm:w-3/4">
            {resources.length > 0 ? (
              <>
                {resources
                  // .filter(applyContentFilters)
                  .map((resource: Resource, i: number) => {
                    return (
                      <div className="mb-4" key={i}>
                        <LinkCard
                          linkPath={`/resources/${resource.slug}`}
                          key={resource.id}
                          image={resource.image?.fluid?.src}
                          title={resource.title}
                        >
                          <div className="w-64 mt-2">
                            <ResourceCardTypePill type={resource.type} />
                          </div>
                          <ContentfulContent
                            content={
                              resource.shortDescription?.shortDescription
                            }
                            classNames="mt-4 text-sm"
                          />
                        </LinkCard>
                      </div>
                    );
                  })}
              </>
            ) : (
              <div className="flex justify-center">
                <p className="text-lg italic text-gray-600">
                  No resources to display
                </p>
              </div>
            )}
          </div>
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default Resources;
